import { ReactElement, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsFillXCircleFill, BsPencilSquare } from "react-icons/bs";
import { MdAdd, MdDone } from "react-icons/md";
import { TbReorder } from "react-icons/tb";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { DashboardTitle } from "../../DashboardTitle/DashboardTitle";
import { SpinnerWrapper } from "../../SpinnerWrapper/SpinnerWrapper";
import {
  CreateOrUpdateWorkoutType,
  fetchWorkouts,
  fetchWorkout,
  selectWorkouts,
  updateWorkout,
  createWorkout,
  selectErrorMessage,
  selectConfirmationMessage,
  setErrorMessage,
  setConfirmationMessage,
  CreateWorkoutExercisesType,
  CreateWorkoutVariablesType,
  CreateWorkoutSetsType,
} from "../../../slices/workoutSlice";
import { fetchUsers, selectUsers } from "../../../slices/userSlice";
import { User } from "../../../types/user";
import { muscleGroups } from "../../../helpers/muscleGroups";
import {
  WorkoutExercise,
  WorkoutExerciseSet,
  Exercise,
  WorkoutExerciseSetVariable,
  ExerciseVariable,
} from "../../../types/exercise";
import { selectIsLoading } from "../../../slices/exerciseSlice";
import { selectIsLoading as selectIsLoadingWorkout } from "../../../slices/workoutSlice";
import { selectIsLoading as selectIsLoadingUsers } from "../../../slices/userSlice";
import { selectIsLoading as selectIsLoadingPersonalTrainers } from "../../../slices/personalTrainerSlice";
import { WorkoutExercises } from "./WorkoutExercises/WorkoutExercises";
import { SelectForm } from "../../SelectForm/SelectForm";
import { DashBoardExercisesPage } from "../../DashboardExercisesPage/DashboardExercisesPage";
import { InputForm } from "../../InputForm/InputForm";
import { TextAreaForm } from "../../TextAreaForm/TextAreaForm";
import ReactGA from "react-ga4";
import { PDFDownloadLink } from "@react-pdf/renderer";

import "./WorkoutPage.css";
import { PiFilePdfBold } from "react-icons/pi";
import { PDFFile } from "../../PDFFile/PDFFile";
import { selectCurrentUser } from "../../../slices/currentUserSlice";
import { WorkoutReorder } from "./WorkoutReorder/WorkoutReorder";
import { GButton } from "components/genericComponents/GButton/GButton";
import { GDatePicker } from "components/genericComponents/GDatePicker/GDatePicker";
import { addMonths } from "date-fns";
import { GInput } from "components/genericComponents/GInput/GInput";
import { fetchPersonalTrainers, selectPersonalTrainers } from "slices/personalTrainerSlice";
import { formatToFirstTwoAndLastName } from "helpers/utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export type SelectDataType = {
  value: string;
  label: string;
};

const initialUpdateWorkoutState: CreateOrUpdateWorkoutType = {
  id: "",
  title: null,
  description: null,
  muscules_groups: null,
  user_id: null,
  workout_exercises: [],
  expiration_date: undefined,
  number_of_sessions: undefined,
  should_reset_sessions: false,
};

export const WorkoutPage = () => {
  ReactGA.send("/workout-details");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workouts = useSelector(selectWorkouts);
  const currentUser = useSelector(selectCurrentUser);
  const users = useSelector(selectUsers);
  const errorMessage = useSelector(selectErrorMessage);
  const confirmationMessage = useSelector(selectConfirmationMessage);

  const workoutId = window.location.href.split("/").slice(-1)[0];
  const workout = workouts.find((workout) => workout.id === workoutId);
  const [formData, setFormData] = useState<CreateOrUpdateWorkoutType>(initialUpdateWorkoutState);
  const [workoutExercisesFetched, setWorkoutExercisesFetched] = useState<boolean>(workoutId === "add-workout");
  const [isNotEditing, setIsNotEditing] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [pickingExercises, setPickingExercises] = useState(false);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number | undefined>(undefined);
  const [exercisesPicked, setExercisesPicked] = useState<Array<Exercise>>([]);
  const [usersFetched, setUsersFetched] = useState(false);
  const exercisesPickedIds = exercisesPicked.map((exercise: Exercise) => exercise.id);
  const [nrSetsToExercises, setNrSetsToExercises] = useState<number>(0);
  const btnRef = useRef<null | HTMLParagraphElement>(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const isGymView = localStorage.getItem("isGymView") === "true";
  const isLoadingExercises = useSelector(selectIsLoading);
  const isLoadingUsers = useSelector(selectIsLoadingUsers);
  const isLoadingWorkout = useSelector(selectIsLoadingWorkout);
  const isLoadingPersonalTrainers = useSelector(selectIsLoadingPersonalTrainers);
  const personal_trainers = useSelector(selectPersonalTrainers);
  const [selectedPersonalTrainer, setSelectedPersonalTrainer] = useState<User | null>(
    workout?.personal_trainer ?? null
  );

  useEffect(() => {
    if (workoutId !== undefined && workoutId !== "add-workout" && workouts.length === 0) dispatch(fetchWorkouts());
  }, [dispatch, workouts, workoutId]);

  useEffect(() => {
    if (users.length === 0 && !usersFetched) {
      dispatch(fetchUsers());
      dispatch(fetchPersonalTrainers());
      setUsersFetched(true);
    }
  }, [dispatch, users, usersFetched]);

  useEffect(() => {
    if (workoutId !== "add-workout") setWorkoutExercisesFetched(false);
  }, [workoutId]);

  useEffect(() => {
    if (pickingExercises && !isLoadingExercises) executeScroll();
  }, [isLoadingExercises, pickingExercises]);

  useEffect(() => {
    if (workout !== undefined) {
      if (!workoutExercisesFetched) {
        dispatch(fetchWorkout(workoutId));
        setWorkoutExercisesFetched(true);
      }
      setFormData({
        id: workout!.id,
        title: workout!.title.trim(),
        description: workout!.description!,
        muscules_groups: workout!.muscules_groups!,
        user_id: workout!.user?.id!,
        workout_exercises:
          workout!.exercises?.map((workoutExercise: WorkoutExercise, index: number) => {
            return {
              id: workoutExercise?.id ?? null,
              exercise: workoutExercise.exercise,
              notes: workoutExercise.notes,
              sets: workoutExercise.sets.map((set: WorkoutExerciseSet) => {
                return {
                  variables: set.variables.map((variable: WorkoutExerciseSetVariable) => {
                    return {
                      id: variable.variable_id,
                      value: variable.value,
                    };
                  }),
                };
              }),
              order: workoutExercise.order === null ? index : workoutExercise.order, // TODO: Remove ternary
            };
          }) ?? [],
        expiration_date: workout!.expiration_date as unknown as string,
        number_of_sessions: workout!.number_of_sessions,
        should_reset_sessions: workout!.should_reset_sessions,
        personal_trainer_id: workout!.personal_trainer?.id,
      });
      setSelectedUser(workout!.user);
      setSelectedPersonalTrainer(workout!.personal_trainer);
    }
    if (workoutId === "add-workout") {
      setIsNotEditing(false);
    }
  }, [workouts, dispatch, workout, workoutId, workoutExercisesFetched]);

  useEffect(() => {
    if (errorMessage !== undefined) {
      toast.error(errorMessage, {
        toastId: "workout-error",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setErrorMessage(undefined));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (confirmationMessage !== undefined) {
      toast.success(confirmationMessage, {
        toastId: "workout-confirmation",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      dispatch(setConfirmationMessage(undefined));

      navigate("../workouts");
    }
  }, [dispatch, confirmationMessage, navigate]);

  const onSubmitButtonClick = () => {
    if (workoutId === "add-workout") {
      dispatch(createWorkout(formData));
    } else {
      dispatch(updateWorkout(formData));
      setIsNotEditing(true);
    }
  };

  const removeExercise = (exerciseIndex: number) => {
    const removedExerciseOrder = formData.workout_exercises[exerciseIndex].order as number;
    const filteredExercises = formData.workout_exercises!.reduce((prev: CreateWorkoutExercisesType[], curr, index) => {
      if (index !== exerciseIndex) {
        prev.push({
          ...curr,
          order: (curr.order as number) < removedExerciseOrder ? curr.order : (curr.order as number) - 1,
        });
        return prev;
      }
      return prev;
    }, []);

    setFormData({
      ...formData,
      workout_exercises: filteredExercises,
    });
  };

  const addSetToExercise = (exerciseIdx: number) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises!.map(
        (workoutExercise: CreateWorkoutExercisesType, eIdx: number) => {
          if (eIdx === exerciseIdx) {
            return {
              ...workoutExercise,
              sets: workoutExercise.sets.concat([
                {
                  variables: workoutExercise.exercise.exercise_category!.variables.map((variable: ExerciseVariable) => {
                    return {
                      id: variable.id!,
                      value: null,
                    };
                  }),
                },
              ]),
            };
          }
          return workoutExercise;
        }
      ),
    });
  };

  const removeSetFromExercise = (exerciseIdx: number, workoutExercisesIdx: number) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises!.map((exercise: CreateWorkoutExercisesType, eIdx: number) => {
        if (eIdx === exerciseIdx) {
          return {
            ...exercise,
            sets: exercise.sets!.filter((set: any, setIdx: number) => setIdx !== workoutExercisesIdx),
          };
        }
        return exercise;
      }),
    });
  };

  const onExerciseSelect = (e: SelectDataType, exerciseIndex: number) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises!.map((exercise, exIdx) => {
        if (exIdx === exerciseIndex) {
          return {
            ...exercise,
            title: e.label,
            id: e.value,
          };
        }
        return exercise;
      }),
    });
  };

  const onExerciseNotesChange = (e: any, exerciseIndex: number) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises!.map((exercise, exIdx) => {
        if (exIdx === exerciseIndex) {
          return {
            ...exercise,
            notes: e.target.value,
          };
        }
        return exercise;
      }),
    });
  };

  const onVariableSetValue = (exerciseIndex: number, setIndex: number, variableId: string, value: string | null) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises!.map((exercise, index) => {
        if (index === exerciseIndex) {
          return {
            ...exercise,
            sets: exercise.sets!.map((set, idx) => {
              if (idx === setIndex) {
                return {
                  variables: set.variables.map((variable: CreateWorkoutVariablesType) => {
                    if (variable.id === variableId) {
                      return {
                        id: variable.id!,
                        value: value,
                      };
                    } else {
                      return variable;
                    }
                  }),
                };
              }
              return set;
            }),
          };
        }
        return exercise;
      }),
    });
  };

  const onExercisePickedForWorkout = (exercise: Exercise) => {
    if (exercisesPickedIds.includes(exercise.id)) {
      setExercisesPicked(exercisesPicked.filter((e: Exercise) => e.id !== exercise.id));
    } else {
      setExercisesPicked(exercisesPicked.concat(exercise));
    }
  };

  const updateWorkoutExercisesNumberOfSets = (sets: number) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises.map((workoutExercise: CreateWorkoutExercisesType) => {
        const setsToAdd = sets - workoutExercise.sets.length;

        if (setsToAdd > 0) {
          const updatedSets = workoutExercise.sets;

          new Array(setsToAdd).fill("").forEach((_: any) => {
            updatedSets.push({
              variables: workoutExercise.exercise.exercise_category!.variables.map((variable: ExerciseVariable) => {
                return {
                  id: variable.id!,
                  value: null,
                };
              }),
            });
          });

          return { ...workoutExercise, sets: updatedSets };
        } else {
          return {
            ...workoutExercise,
            sets: workoutExercise.sets.slice(0, sets),
          };
        }
      }),
    });
  };

  const copyFirstSetForTheOthers = (workoutExerciseIndex: number) => {
    setFormData({
      ...formData,
      workout_exercises: formData.workout_exercises.map(
        (workoutExercise: CreateWorkoutExercisesType, index: number) => {
          const firstSet = workoutExercise.sets[0];

          if (workoutExerciseIndex === index) {
            return {
              ...workoutExercise,
              sets: workoutExercise.sets.map((set: CreateWorkoutSetsType, setIndex: number) => {
                return { ...firstSet };
              }),
            };
          } else {
            return { ...workoutExercise };
          }
        }
      ),
    });
  };

  const executeScroll = () => {
    btnRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const setExercises = (exercises: CreateWorkoutExercisesType[]) => {
    setFormData({
      ...formData,
      workout_exercises: exercises,
    });
  };

  return (
    <div id="workout-page">
      <DashboardTitle
        title={formData?.title !== null && formData?.title !== "" ? formData?.title : "Criar um novo Plano de Treino"}
        showAddButton={false}
      />
      {isLoadingWorkout || isLoadingUsers || isLoadingPersonalTrainers ? (
        <SpinnerWrapper />
      ) : (
        <>
          <div id="workout-page-outer-container">
            <Container fluid className="workout-page-container">
              <Row>
                <Col className="workout-page-edit-training-plan-col">
                  {isNotEditing ? (
                    isLoadingExercises ? (
                      <GButton>
                        <AiOutlineLoading3Quarters className="loading-animation" />
                      </GButton>
                    ) : (
                      <PDFDownloadLink
                        document={
                          <PDFFile
                            title={formData.title!}
                            description={formData.description!}
                            exercises={workout?.exercises!}
                            createdAt={workout?.created_at?.slice(0, 10)!}
                            createdBy={formatToFirstTwoAndLastName(currentUser?.name!)}
                            clientName={formatToFirstTwoAndLastName(selectedUser?.name!)}
                            gymLogo={selectedUser?.company?.image_large!}
                            maxHeartFreq={selectedUser?.max_heart_freq!}
                          />
                        }
                        fileName={`${formData?.title}${
                          selectedUser !== undefined && selectedUser !== null
                            ? ` - ${formatToFirstTwoAndLastName(selectedUser.name)}`
                            : ""
                        }.pdf`}
                        className="personal-data-pdf-button"
                        children={<PiFilePdfBold />}
                      />
                    )
                  ) : undefined}
                  {workoutId !== "add-workout" &&
                    (isNotEditing ? (
                      <Button className="personal-data-edit-button" onClick={() => setIsNotEditing(!isNotEditing)}>
                        <BsPencilSquare />
                      </Button>
                    ) : (
                      <Button
                        className="personal-data-edit-button-close"
                        onClick={() => setIsNotEditing(!isNotEditing)}
                      >
                        <BsFillXCircleFill />
                      </Button>
                    ))}
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <InputForm
                    value={formData?.title}
                    label="Título"
                    disabled={isNotEditing}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        title: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={12} lg={3} className="mb-3">
                  <SelectForm
                    options={users.map((user: User) => {
                      return {
                        id: user.id,
                        title: `${formatToFirstTwoAndLastName(user.name)}${
                          user.identifier ? ` - ${user.identifier}` : ""
                        }`,
                        image: user.image_small,
                      };
                    })}
                    value={
                      selectedUser === undefined || selectedUser === null
                        ? undefined
                        : {
                            id: selectedUser.id,
                            title: formatToFirstTwoAndLastName(selectedUser.name),
                            image: selectedUser.image_small,
                          }
                    }
                    label="Cliente"
                    onChange={(e: any) => {
                      setSelectedUser(users.find((user: User) => user.id === e.value));
                      setFormData({
                        ...formData,
                        user_id: e !== null ? e.value : e,
                      });
                    }}
                    disabled={isNotEditing}
                    hasImage={true}
                  />
                </Col>
                {isGymView && (
                  <Col xs={12} lg={3} className="mb-3">
                    <SelectForm
                      isClearable={true}
                      disabled={isNotEditing}
                      options={personal_trainers.map((personal_trainer: User) => {
                        return {
                          id: personal_trainer.id!,
                          title: formatToFirstTwoAndLastName(personal_trainer.name),
                          image: personal_trainer.image_small,
                        };
                      })}
                      value={
                        selectedPersonalTrainer === undefined || selectedPersonalTrainer === null
                          ? undefined
                          : {
                              id: selectedPersonalTrainer.id!,
                              title: formatToFirstTwoAndLastName(selectedPersonalTrainer.name),
                              image: selectedPersonalTrainer.image_small,
                            }
                      }
                      hasImage={true}
                      label="Personal Trainer"
                      onChange={(e: any) => {
                        if (e === null) {
                          setSelectedPersonalTrainer(null);
                        } else {
                          setSelectedPersonalTrainer(
                            personal_trainers.find((personal_trainer: User) => personal_trainer.id === e.value)!
                          );
                          setFormData({
                            ...formData,
                            personal_trainer_id: e !== null ? e.value : e,
                          });
                        }
                      }}
                    />
                  </Col>
                )}
                <Col xs={12} lg={3}>
                  <SelectForm
                    options={Object.keys(muscleGroups).map((muscleGroup: string) => {
                      return {
                        id: muscleGroup,
                        title: muscleGroups[muscleGroup].pt,
                      };
                    })}
                    multiValue={
                      formData.muscules_groups === null
                        ? undefined
                        : formData.muscules_groups.map((muscleGroup: string) => {
                            return {
                              id: muscleGroup,
                              title: muscleGroups[muscleGroup].pt,
                            };
                          })
                    }
                    label="Grupos de Músculos"
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        muscules_groups:
                          e.length !== 0 ? e.map((obj: { value: string[]; label: ReactElement }) => obj.value) : null,
                      })
                    }
                    disabled={isNotEditing}
                    isMultiSelect={true}
                  />
                </Col>
              </Row>
              <Row className="workout-page-expiration-date-row">
                <Col>
                  <p className="workout-page-expiration-date-title">Prazo de validade</p>
                  <GDatePicker
                    currDate={
                      formData.expiration_date === undefined || formData.expiration_date === null
                        ? addMonths(new Date(), 1)
                        : new Date(formData.expiration_date)
                    }
                    setCurrDate={(val: Date | undefined) => {
                      setFormData({
                        ...formData,
                        expiration_date: val!.toISOString(),
                      });
                    }}
                    disabled={isNotEditing}
                  />
                </Col>
                <Col>
                  <p className="workout-page-expiration-date-title">Número de sessões a realizar</p>
                  <GInput
                    value={formData.number_of_sessions}
                    type="number"
                    min={0}
                    onChange={(val: any) =>
                      setFormData({
                        ...formData,
                        number_of_sessions: val.target.value,
                      })
                    }
                    disabled={isNotEditing}
                  />
                  {workout !== undefined && (
                    <Form.Check // prettier-ignore
                      disabled={isNotEditing}
                      checked={formData.should_reset_sessions}
                      type="switch"
                      label="Recomeçar contagem de sessões"
                      className="purple-switch"
                      onChange={(val: any) =>
                        setFormData({
                          ...formData,
                          should_reset_sessions: val.target.checked,
                        })
                      }
                    />
                  )}
                </Col>
                {workout && (
                  <Col>
                    <p className="workout-page-expiration-date-title">Número de sessões completas</p>
                    <GInput value={workout.number_of_completed_sessions} disabled />
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <TextAreaForm
                    label="Descrição"
                    value={formData.description}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        description: e.target.value,
                      })
                    }
                    disabled={isNotEditing}
                    large={true}
                  />
                </Col>
              </Row>
              <Row className="workout-page-exercises-row">
                <Col className="workout-page-exercises-col">
                  <p className="workout-page-exercises-title" ref={btnRef}>
                    Exercícios
                  </p>
                  {!isNotEditing && !pickingExercises && (
                    <button
                      className="dashboard-title-btn"
                      onClick={() => {
                        setPickingExercises(true);
                        setIsSidebarVisible(false);
                        //executeScroll();
                      }}
                    >
                      <MdAdd />
                    </button>
                  )}
                  {!isNotEditing && !pickingExercises && formData.workout_exercises.length > 1 && (
                    <Button onClick={() => setIsSidebarVisible(true)} className="dashboard-title-btn">
                      <TbReorder />
                    </Button>
                  )}
                  {!isNotEditing && !pickingExercises && (
                    <div className="nr-sets-to-exercises-div">
                      <p>Número de sets a aplicar aos exercícios:</p>
                      <Form.Group className="nr-sets-to-exercises-input">
                        <Form.Control
                          type="number"
                          max={20}
                          min={0}
                          value={nrSetsToExercises}
                          onChange={(e) => {
                            if (Number.parseInt(e.target.value) > 20) setNrSetsToExercises(20);
                            else if (Number.parseInt(e.target.value) < 0) setNrSetsToExercises(0);
                            else setNrSetsToExercises(Number.parseInt(e.target.value));
                          }}
                        />
                      </Form.Group>
                      <Button
                        className="personal-data-submit-button-close"
                        onClick={() => updateWorkoutExercisesNumberOfSets(nrSetsToExercises)}
                      >
                        Aplicar
                      </Button>
                    </div>
                  )}
                  {!isNotEditing && pickingExercises && (
                    <Button
                      onClick={() => {
                        const exercises: Array<CreateWorkoutExercisesType> = exercisesPicked.map(
                          (exercise: Exercise, index: number) => {
                            return {
                              exercise: exercise,
                              notes: null,
                              sets: [
                                {
                                  variables: exercise.exercise_category!.variables.map((variable: ExerciseVariable) => {
                                    return {
                                      id: variable.id!,
                                      value: null,
                                    };
                                  }),
                                },
                              ],
                              order: formData.workout_exercises.length + index,
                            };
                          }
                        );
                        setPickingExercises(false);
                        setFormData({
                          ...formData,
                          workout_exercises: [...formData.workout_exercises, ...exercises],
                        });
                        setExercisesPicked([]);
                      }}
                      className="dashboard-title-btn cancel"
                    >
                      <MdDone />
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {!workoutExercisesFetched ? (
                    <SpinnerWrapper />
                  ) : (
                    <div>
                      {pickingExercises ? (
                        <DashBoardExercisesPage
                          currentExerciseIndex={currentExerciseIndex}
                          isPickingExerciseForWorkout={true}
                          setExercisePickedForWorkout={onExercisePickedForWorkout}
                          exerciseIdsPickedForWorkout={exercisesPickedIds}
                        />
                      ) : (
                        <div>
                          {formData.workout_exercises?.map(
                            (exercise: CreateWorkoutExercisesType, exerciseIndex: number) => {
                              return (
                                <WorkoutExercises
                                  workoutExercise={exercise}
                                  exerciseIndex={exerciseIndex}
                                  key={exercise.exercise.id! + exerciseIndex}
                                  isNotEditing={isNotEditing}
                                  onExerciseSelect={onExerciseSelect}
                                  onExerciseNotesChange={onExerciseNotesChange}
                                  removeExercise={removeExercise}
                                  formData={formData}
                                  addSetToExercise={addSetToExercise}
                                  removeSetFromExercise={removeSetFromExercise}
                                  setCurrentExerciseIndex={setCurrentExerciseIndex}
                                  setPickingExercises={setPickingExercises}
                                  onVariableSetValue={onVariableSetValue}
                                  copyFirstSetForTheOthers={copyFirstSetForTheOthers}
                                />
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="submit-exercise-col">
                  {!isNotEditing && !pickingExercises && <GButton label="Submeter" onClick={onSubmitButtonClick} />}
                </Col>
              </Row>
            </Container>
          </div>
          <WorkoutReorder
            isVisible={isSidebarVisible && !pickingExercises}
            setIsSidebarVisible={setIsSidebarVisible}
            exercises={formData.workout_exercises}
            setExercises={(exercises: CreateWorkoutExercisesType[]) => setExercises(exercises)}
          />
        </>
      )}
    </div>
  );
};
